import { InertiaLink, InertiaLinkProps } from '@inertiajs/inertia-react'
import React from 'react'

export interface LinkProps extends InertiaLinkProps {
    defaultColor?: boolean
    defaultPadding?: boolean
}

function Button(props: LinkProps) {
    const {className, defaultColor = true, defaultPadding = true, ...rest} = props

    return (
        <InertiaLink className={`h-50px ${defaultColor && "text-white bg-brown-light hover:bg-brown-dark"}    ${defaultPadding && "px-22px"} inline-flex items-center justify-center font-bold ${className}`} {...rest} />
    )
}

export default Button

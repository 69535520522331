import { InertiaLink } from '@inertiajs/inertia-react'
import { motion } from 'framer-motion'
import route from 'ziggy-js'
import { t } from '../Translator'


export default function OpeningWidget({dark}:{dark?:boolean}) {

    const data = [
        { day: 1, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 21, 0) },
        { day: 2, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 22, 0) },
        { day: 3, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 22, 0) },
        { day: 4, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 22, 0) },
        { day: 5, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 23, 0) },
        { day: 6, openedFrom: new Date(0, 0, 0, 7, 30), openedTo: new Date(0, 0, 0, 23, 0) },
        { day: 0, openedFrom: new Date(0, 0, 0, 8, 0), openedTo: new Date(0, 0, 0, 20, 0) },
    ]

    function today() {
        let today = new Date()

        const returned = data.filter((single) => (single.day == today.getDay()))[0]

        // console.log("obj ",returned)
        // console.log("today hours ",today.getHours())
        // console.log("obj hours ",returned.openedTo.getHours())

        if (today.getHours() <= returned.openedFrom.getHours()) {
            return t("Dnes") + " " + t("od") + " "+returned.openedFrom.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
        }
        else if (today.getHours() <= returned.openedTo.getHours() || returned.openedTo.getHours() == 0) {
            return t("Dnes") + " " + t("do") + " "+returned.openedTo.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
        }
        else {
            return t("Zítra") + " " + t("od") + " "+ data.filter((single) => (single.day == (today.getDay()+1)))[0].openedFrom.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
        }

    }


    return (
        <InertiaLink href={route('kontakt')} className="flex items-center">
            <motion.div
                transition={{ repeat: Infinity, duration: 1.6 }}
                animate={{ opacity: 0 }}
                initial={{ opacity: 1 }}
                className={`w-4px h-4px ${dark ? " bg-white" : " bg-brown-dark"}  mr-8px rounded-full`}></motion.div>
            <div className={`${dark ? " text-white " : " text-brown-dark"} font-semibold text-14`}>{today()}</div>
        </InertiaLink>
    )
}
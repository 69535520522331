import { Page } from '@inertiajs/inertia'
import { InertiaLink, usePage } from '@inertiajs/inertia-react'
import usePageProps from 'app/components/hooks/usePageProps'
import Icon from 'app/components/Icon'
import Img from 'app/components/Img'
import React, { useEffect, useRef, useState } from 'react'
import route from 'ziggy-js'

interface Props {
}

function MenuItem({ href, activeName, name, icon, can }: { href: string, activeName: string, name: string, icon: string, can?: string }) {

    const { user } = usePageProps<{ user: { can: Record<string, boolean> } }>();

    return (

        (!can || user.can?.[can]) ?
            <InertiaLink href={href} className="flex min-w-max">
                <div id={`link${icon}`} className={`flex group relative p-8px justify-center hover:bg-brown-dark hover:text-white rounded-default items-center ${route()?.current()?.startsWith(activeName) && " bg-brown-light text-white"} `}>
                    <Icon className="h-30px" name={icon} />
                    <div className={(`item group-hover:opacity-100 pointer-events-none absolute text-16 -mr-20px -mt-6px rounded-sm bg-black top-0 p-12px z-99999 right-0 text-white transform translate-x-full opacity-0`)}>
                        <div className="whitespace-nowrap">{name}</div>
                        <div className="absolute top-0 left-0 rounded-sm -ml-5px mt-20px w-12px h-12px bg-black transform rotate-45"></div>
                    </div>
                </div>

            </InertiaLink>
            : null
    )
}

const links = [
    { href: route('admin.index'), name: "Menu", icon: "tea", activeName: "menu", },
    // { href: route('herbal.index'), name: "Herbár", icon: "herbal", activeName: "herbal", can: "manage uncomplete"  },
    // { href: route('shrooms.index'), name: "Houbář", icon: "shrooms", activeName: "shrooms", can: "manage uncomplete"  },
]

function SideMenu(props: Props) {
    const { } = props
    const { user } = usePageProps<{ user: { can: Record<string, boolean> } }>();



    return (
        <div className="bg-white border-r border-brown-light h-auto px-24px pb-85px min-h-screen min-w-max">
            <InertiaLink href={route('admin.index')} className='h-85px flex items-center justify-center mb-60px'>
                <Img src='/assets/img/logo.svg' />
            </InertiaLink>
            <div className='flex justify-center h-full w-full items-start'>
                {
                    links.map(l => <MenuItem {...l} />)
                }
            </div>
        </div>
    )
}

export default SideMenu

import React, { HTMLProps } from 'react'

interface Props extends HTMLProps<HTMLDivElement> {
    className?:string
}

function WhiteContainer(props: Props) {
    const {children, className} = props

    return (
        <div className={`p-16px bg-white border border-brown-light  ${className}`}>
            {children}
        </div>
    )
}

export default WhiteContainer

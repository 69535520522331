import { InertiaLink } from '@inertiajs/inertia-react';
import { defaultButtons } from 'app/admin/ConfirmModal';
import Layout from 'app/admin/Layout'
import WhiteContainer from 'app/admin/WhiteContainer';
import Button from 'app/components/Buttons/Button';
import InvertedButton from 'app/components/Buttons/InvertedButton';
import useLazyLoad from 'app/components/hooks/useLazyLoad';
import Icon from 'app/components/Icon';
import { MODALS, ModalsContext } from 'app/components/Layouts/Modals';
import Form from 'app/fragments/Forms/Form';
import Select from 'app/fragments/Forms/Select';
import React, { useContext } from 'react'
import route from 'ziggy-js';

interface Props { }

export function AdminMeta(props) {
    return (
        <div className="flex justify-between items-center mt-16px">
            {!props?.hideNumbers && <div className="text-16">Zobrazujem {props.meta.to} z {props.meta.total} celkem</div>}
            <div className="flex-grow  mx-16px"></div>
            {props.meta?.next > 0 && // <InvertedButton href="/" color="6e8047" >Dalších {meta.next}</InvertedButton>
                <InvertedButton color="6e8047" href="" {...props.button}  >Načítať ďalšie<Icon className="w-14px ml-10px" name="dropdown-arrow" /></InvertedButton>}
        </div>
    );
}

function Menu(props: Props) {
    const { } = props
    console.log(props);
    const [menus, button, meta, form, setMenus] = useLazyLoad<{ id, name, date, price }>('menus');
    const { data, setData } = form;
    const { open, close } = useContext(ModalsContext)

    // useEffect(() => {
    //     setData(d => ({
    //         ...d,
    //         category: active
    //     }))
    // }, [])

    const removeProduct = (e, id) => {
        e.preventDefault();

        open(MODALS.CONFIRM, false, {
            title: "Potvrdit smazání",
            message: "Opravdu chcete smazat položku?",
            buttons: defaultButtons(
                route('menu.destroy', { menu: id }),
                () => {
                    setMenus(pr =>
                        pr.filter(f => f.id != id)
                    );
                    close();
                },
                close
            )
        })
    }

    return (
        <Layout>
            {/* <Head title="Produkty | Serafin byliny Admin" /> */}
            <div className=" pt-40px pb-130px">
                <div className="flex justify-between items-center gap-32px">
                    <div className="text-h32 font-semibold flex-shrink-0">Menu</div>
                    <div className='flex items-center gap-32px'>
                        <div className="flex items-center mt-18px justify-end">
                            <div className="mr-16px">Položek na stránku</div>
                            <Form form={form}>
                                <Select name="paginate"
                                    placeholder="10"
                                    options={[
                                        { text: "10", value: "10" },
                                        { text: "20", value: "20" },
                                        { text: "50", value: "50" },
                                        { text: "100", value: "100" },
                                    ]} />
                            </Form>
                        </div>
                        <div className='flex gap-16px'>
                            <Button href={route('menu.create')} >
                                <Icon className="pr-16px" name="tick" />
                                Přidat položku
                            </Button>
                        </div>
                    </div>
                </div>

                <WhiteContainer className="mt-32px px-0 pb-0 pt-0">
                    <Form form={form} >
                        <div className="px-16px grid grid-cols-6 py-8px gap-x-12px bg-brown-light text-white font-semibold">
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>ID</div>
                            </div>
                            <div className="flex items-center col-span-2">
                                <div className="mr-16px">Jméno</div>
                            </div>
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>Cena</div>
                            </div>
                            <div className="flex items-center">
                                <div className={(`mr-16px `)}>Datum</div>
                            </div>
                            <div className="flex justify-end">
                                <div className="flex items-center">
                                    <div className="mr-16px">Akce</div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {
                        menus.map((p, i) =>
                            <div key={`menu-${p.id}`} className="border-t border-brown-light grid grid-cols-6 px-16px py-8px text-16 gap-x-12px">
                                <div className="items-center flex "><InertiaLink href={route("menu.edit", { menu: p.id })}>{p.id}</InertiaLink></div>
                                <div className="items-center flex col-span-2"><InertiaLink href={route("menu.edit", { menu: p.id })}>{p.name}</InertiaLink></div>
                                <div className="flex">{p.price}</div>
                                <div className="flex">{p.date}</div>
                                <div className="flex justify-end items-center">
                                    <div className="flex items-end">
                                        <InertiaLink href={route("menu.edit", { menu: p.id })}><Icon className="mr-15px" name="edit" /></InertiaLink>
                                        <button
                                            onClick={e => removeProduct(e, p.id)}
                                        >
                                            <Icon className="text-red" name="bin" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </WhiteContainer>
                <AdminMeta meta={meta} button={button} />
            </div>
        </Layout>
    )
}

export default Menu


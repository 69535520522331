import Button from "app/components/Button";
import ContentContainer from "app/components/ContentContainer";
import Header from "app/components/Header/Main";
import OpeningWidget from "app/components/Header/OpeningWidget";
import Img from "app/components/Image";
import Layout from "app/components/Layouts/Layout";
import MenuAndReserve from "app/components/MenuAndReserve";
import { motion } from "framer-motion";
import { stringify } from "postcss";
import { Children, useEffect, useState } from "react";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Map from 'components/Map'
import JanLibra from "app/components/JanLibra";
import InstagramGrid from "app/components/InstagramGrid";
import { browserName } from 'react-device-detect';
import { t, _ } from "app/components/Translator";
import Kosvanec from "app/components/Kosvanec";





export default function Kontakt() {

    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        if (clicked) {
            setTimeout(() => {
                setClicked(false)
            }, 1000)

        }
    }, [clicked])


    return (
        <Layout onlyReserve>
            <MenuAndReserve onlyReserve>
                <Header wide relative />
                <div className="w-full grid grid-cols-1 md:grid-cols-2">
                    <div className="relative w-full order-2 md:order-1">
                        <Img src="/assets/img/contact_img.jpg" layout="fill" objectFit="cover" />
                    </div>
                    <div className="w-full flex justify-center items-center py-16px md:py-80px md:pr-60px order-1 md:order-2">
                        <div className="md:max-w-lg w-full px-16px md:px-48px ">
                            <h1 className="lowercase font-aldo font-outline-brown text-48 md:text-64 leading-tight"><_>Kontakt</_></h1>
                            <div className="block lg:flex mb-16px"><div className="font-semibold text-black"><_>Pro rezervaci a pronájem volejte na:</_>&nbsp; </div><a href="tel:+420731592200" className="text-brown-light hover:text-brown-text">+420 731 592 200</a></div>
                            <div className="block lg:flex mb-16px"><div className="font-semibold text-black"><_>Pro pronájem volejte na:</_>&nbsp; </div><a href="tel:+420734391471" className="text-brown-light hover:text-brown-text">+420 734 391 471</a></div>
                            <a href="https://m.me/cafenewone" target="_blank">
                                <Button value={t("Napsat na Messenger")} icon="messenger" />
                            </a>
                            <h2 className="py-24px font-semibold text-black text-18 md:text-24"><_>Otevírací doba</_></h2>
                            <Opening day={t("pondělí")} value="7:30—21:00" />
                            <Opening day={t("úterý—čtvrtek")} value="7:30—22:00" />
                            <Opening day={t("pátek—sobota")} value="7:30—23:00" />
                            <Opening day={t("neděle")} value="8:00—20:00" />
                        </div>

                    </div>
                    <div className="w-full flex justify-center items-center py-16px md:py-80px order-4 md:order-3">
                        <div className="md:max-w-lg w-full px-16px md:px-24px ">
                            <h2 className="py-24px font-semibold text-black text-18 md:text-24"><_>Adresa</_></h2>
                            <div className="grid grid-cols-2">
                                <div>
                                    třída Svobody 21<br />
                                    779 00 Olomouc
                                </div>
                                <div className="flex w-full">
                                    <a onClick={() => setClicked(true)} href={(browserName.includes("Safari")) ? "http://maps.apple.com/?q=Cafe New One" : "https://www.google.com/maps/dir//tř%C3%ADda+Svobody+21,+Olomouc/@49.7526687,16.7787764,10z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47124ef44b7da125:0xa1ca50b9f9b90579!2m2!1d17.24807!2d49.5938772!3e0?hl=cs"} target="_blank">
                                        <Button value={clicked ? t("Výpočet trasy...") : t("Vypočítat trasu")} forceFullWidth />
                                    </a>
                                </div>

                            </div>
                            <h2 className="py-24px font-semibold text-black text-18 md:text-24"><_>Fakturační údaje</_></h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-24px ">
                                <div>
                                    BALASHOUSE s. r. o.<br />
                                    třída Svobody 21<br />
                                    770 00 Olomouc
                                </div>
                                <div>
                                    IČ: 02663163<br />
                                    DIČ: CZ02663163 <br />
                                    KS Ostrava odd. C, vložka 58307 <br />
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="relative w-full order-3 md:order-4 h-300px md:h-auto">
                        <Map />
                    </div>
                </div>
                <ContentContainer onlyReserve>
                    <Kosvanec />
                </ContentContainer>
                <div className="lg:hidden">
                    <ContentContainer p onlyReserve>
                        <p className="block lg:hidden text-16 text-brown-text mb-16px">
                        Manažerské zkušenosti v průběhu let získal v několika prvotřídních hotelových restauracích v Dublinu a Londýně, jeho srdce si však nakonec získala Olomouc, a když se naskytnula možnost stát se součástí příběhu Café New One, prý neváhal ani minutu. Z pozice F&B Managera dohlíží na to, aby se u nás všechno fungovalo jako na drátkách.
                        </p>
                        <div className="block lg:hidden border-l border-brown-light pl-24px text-16 italic text-brown-text">
                        „<_>Hlavní kouzlo všeho, co děláme, tkví v osobním přístupu. Chci, aby každý, kdo do Café New One zavítá, cítil, že přišel na místo, kde ho lidé dobře znají.</_>“
                        </div>
                    </ContentContainer>
                </div>
                <ContentContainer p onlyReserve>
                    <InstagramGrid />
                </ContentContainer>
            </MenuAndReserve>
        </Layout>

    )
}

interface SectionProps {
    title: string,
    name: string,
    subtitle?: string,
    children: any,
    time?: string
}

export function Opening({ day, value }) {

    return (
        <div className="w-full flex justify-between items-bottom relative mb-16px">

            <div className=" z-10 text-16 font-normal text-brown-text max-w-sm"><span className="bg-white  pr-8px">{day}</span></div>
            <div className="bg-white z-10 pl-8px text-16 font-normal text-brown-text self-end whitespace-nowrap">{value}</div>
            <div className="z-0 absolute bottom-6px h-1px w-full bg-brown-dark opacity-16"></div>
        </div>
    )
}
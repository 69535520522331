import Img from "./Image";
import { _ } from "./Translator";


export default function JanLibra() {

    return (
        <div className="w-full h-250px md:h-450px lg:h-815px grid grid-cols-1 lg:grid-cols-3 relative" name="slovo-sefkuchare">
            <div className="hidden lg:block"></div>
            <div className="hidden lg:block 2xl:hidden"></div>
            <div className=" h-full flex flex-col justify-end lg:justify-center items-start z-10 text-white p-16px pr-60px 2xl:pl-200px 2xl:col-span-2 2xl:pr-200px">
                <h2 className="text-48 md:text-64 font-outline font-aldo">Jan Libra</h2>
                <p className="text-16"><_>Šéfkuchař Café New One</_></p>
                <p className="hidden lg:block py-16px text-16">
                    <_>Začínal v hotelové kuchyni, kde projevil svůj talent a během dvou let se vypracoval z pozice Chef de Partie na Sous-chef. Tehdy stál u zrodu Café New One, později na dva roky Caféčko opustil, aby nasbíral zkušenosti a inspiraci v zahraničí, zejména na Islandu, v Irsku a na Maltě a vrátil se rovnou na pozici šéfkuchaře.</_>
                </p>
                <div className="hidden lg:block border-l border-white pl-24px text-16 italic">
                    „<_>V Caféčku pro vás chceme vždy víc</_>“
                </div>
            </div>
            <Img src="/assets/img/signatures/libra.svg" className="absolute z-10  bottom-0 lg:bottom-50px right-0 lg:right-0 lg:left-300px lg:mx-auto w-1/2 md:w-300px" />
            <Img src="/assets/img/libra.jpeg" className="absolute transform scale-x-flip z-0 top-0 left-0 bottom-0 right-0 object-top" layout="fill" objectFit="cover" />
            <div className="absolute top-0 left-0 bottom-0 right-0  bg-black opacity-25 md:hidden"></div>
        </div>
    )
}
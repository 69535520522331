import React, { HTMLProps } from 'react'

interface Props extends HTMLProps<HTMLDivElement> {

}

function EditWrapper(props: Props) {
    const {children} = props

    return (
        <div className={(`max-w-4xl mx-auto`)}>
            {children}
        </div>
    )
}

export default EditWrapper

import ContentContainer from "app/components/ContentContainer";
import Header from "app/components/Header/Main";
import Img from "app/components/Image";
import InstagramGrid from "app/components/InstagramGrid";
import JanLibra from "app/components/JanLibra";
import Layout from "app/components/Layouts/Layout";
import MenuAndReserve from "app/components/MenuAndReserve";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, scroller } from 'react-scroll'
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { t, TranslationProps, _ } from "app/components/Translator";
import moment from "moment";
import { format } from "date-fns";
import usePageProps from "app/components/usePageProps";
import { PageProps } from "@inertiajs/inertia";


interface MenuProps {
    menus: any
}


export default function Menu(props: MenuProps) {

    const [dailyMenu, setDailyMenu] = useState(null as any)
    const [scrolled, setScrolled] = useState(0);
    const [dayMenu, inView] = useInView()

    const { menus } = props

    // useEffect(() => {
    //     console.log("inviws", inView)
    //     if ((inView == true)) {
    //         setTimeout(() => {
    //             if (inView == true) {
    //                 console.log("fetching menu...")
    //                 if (!dailyMenu) {
    //                     getMenu()
    //                 }
    //             }
    //         }, 1000)

    //     }

    // }, [inView])

    // async function getMenu() {

    //     axios.get("/api/menu"
    //     )
    //         .then((response) => {
    //             console.log(response.data)
    //             setDailyMenu(renderMenu(response.data))
    //         })
    // }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('section') as string | null

        if (myParam && (scrolled <= 1)) {
            // alert("scroll: "+myParam)
            scroller.scrollTo(myParam)
            setScrolled(scrolled + 1)
        }

    });



    return (
        <Layout onlyReserve dark>
            <MenuAndReserve onlyReserve dark>
                <Header wide relative dark />
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="block lg:flex items-start bg-brown-dark text-white">
                    <div className="z-50 md:z-auto flex flex-grow-0 flex-shrink-0 w-full lg:w-200px sticky bg-brown-dark top-0 md:top-75px lg:top-1/4 self-start pt-16px pr-16px pl-16px lg:p-25px lg:flex-col justify-start items-start text-16 text-brown-inverted lg:gap-y-24px gap-x-24px overflow-x-auto lg:overflow-x-hidden border-b border-brown-dark lg:border-none border-opacity-16">

                        <Link activeClass="border-b border-browm-light text-white " className=" pb-10px cursor-pointer transition duration-200 whitespace-nowrap" to="denni-menu" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Denní menu</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="a-la-carte" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Menu á la carte</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="snidane" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Snídaně á la carte</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="napojovy-list" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Nápojový list</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="vinny-list" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Vinný list</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white" className="  pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="cocktail-menu" spy={true} hashSpy smooth={true} offset={-90} duration={500}>
                            <_>Cocktail menu</_>
                        </Link>
                        <Link activeClass="border-b border-browm-light text-white " className=" pb-16px cursor-pointer transition duration-200 whitespace-nowrap" to="slovo-sefkuchare" spy={true} smooth={true} offset={-90} duration={500}>
                            <_>Slovo šéfkuchaře</_>
                        </Link>


                    </div>

                    <div className="flex-grow-1 flex-shrink-1 w-full">
                        <ContentContainer p onlyReserve menuShift>
                            <Section
                                title="Obědové menu"
                                name="denni-menu"
                                subtitle="V ceně poledního menu je džbán 0,25l kohoutkové vody a naše focaccia."
                                time="Polední menu podáváme od 11:30 do 14:00"
                            >
                                <motion.div
                                    animate={{ maxHeight: menus ? 1999 : 250 }}
                                    transition={{ duration: 2 }}
                                    ref={dayMenu} className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-x-120px overflow-hidden min-h-300px">
                                    {Object.entries(menus)?.map(([key, day]: any, i) => (
                                        <motion.div
                                            animate={{ opacity: 1 }}
                                            initial={{ opacity: 0 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <h3 className="text-18 md:text-24 font-semibold my-20px">{(moment(new Date(), 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.') == moment(key, 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.') ? "Dnes" : moment(key, 'YYYY-MM-DD').locale('cs-CZ').format('dd D.M.'))}</h3>
                                            {day.map((meal) => (
                                                <Item name={meal.name} price={meal.price} />
                                            ))}

                                        </motion.div>
                                    ))}
                                    {!menus &&
                                        <div>
                                            <h3 className="text-18 md:text-24 font-semibold my-20px w-1/4 h-24px rounded load"></h3>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                            <div className="load w-full h-24px my-20px" ></div>
                                        </div>
                                    }

                                </motion.div>
                            </Section>
                            <Section
                                title={"Menu á la carte"}
                                name="a-la-carte"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-120px">
                                    <div>

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Předkrmy</_></h3>
                                        <Item name="Hovězí tatarák" subtitle="šalotka, česneková majonéza, hrubozrnná hořčice, ciabatta" price="279  " />
                                        <Item name="Tygří krevety na víně a česneku" subtitle="na víně a česneku" price="269  " />
                                        <Item name="Bruschetta" subtitle="datlová rajčata, bazalkové pesto, burrata, lanýžový olej" price="189  " />
                                        {/* <div className="relative overflow-hidden my-20px">
                                            <Img src="/assets/img/menu_photo_1.jpg" width="100%" layout="fill" objectFit="cover" />
                                        </div> */}
                                        <Item name="Selekce italských sýrů a uzenin, olivy taggiasche, sušená rajčata, pinie, grissini" price="279  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Polévky</_></h3>
                                        <Item name="Kuřecí vývar" subtitle="maso, zelenina, celestýnské nudle, pažitka" price="89  " />
                                        <Item name="Rajčatový krém" subtitle="zakysaná smetana, bazalka" price="99  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px">Pasta a gnocci</h3>
                                        <div className={`grid grid-cols-1 gap-x-0 text-brown-inverted`}>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <span className="font-semibold">1) Spaghetti freschi</span> <_>(s příplatkem 20  )</_>
                                            </div>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <span className="font-semibold">2) Spaghetti di semola</span>
                                            </div>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <span className="font-semibold">3) Pappardelle</span>
                                            </div>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <span className="font-semibold">4) Penne</span>
                                            </div>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <span className="font-semibold">5) Gnocchi</span>
                                            </div>
                                            <div className="py-16px border-b border-brown-light  border-opacity-16">
                                                <_>Všechny těstoviny obsahují stopy lepku a vajec.</_>
                                            </div>
                                        </div>

                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Varianty</_></h4>
                                        <Item name="1) Aglio olio" subtitle="e peperoncino / rib eye steak" price="189/289  " />
                                        <Item name="2) Alla carbonara" subtitle="guanciale, žloutky, parmazán" price="269  " />
                                        <Item name="3) Alla bolognese" subtitle="hovězí ragú, pecorino" price="279  " />
                                        <Item name="4) Gamberetti" subtitle="sugo, cherry rajčata, olivy taggiasche" price="299  " />
                                        <Item name="5) Alfredo" subtitle="parmazán, krůtí prso, smažená cibulka, smetana" price="289  " />
                                        <Item name="6) Con funghi porcini" subtitle="parmazán, hřiby, smetana" price="269  " />
                                        <Item name="7) Ravioli alla Napoletana" subtitle="prosciutto, ricotta, omáčka ze sušených rajčat, bazalka" price="279  " />
                                        <Item name="8) Lasagne se špenátem" subtitle="parmazán, gorgonzolová omáčka" price="249  " />
                                    </div>
                                    <div>
                                        <h3 className="text-18 md:text-24 font-semibold my-20px">Risotto</h3>
                                        <Item name="Risotto s liškami" subtitle="guanciale, parmazán, máslo" price="289  " />
                                        {/* <div className="relative overflow-hidden my-20px">
                                            <Img src="/assets/img/menu_photo_2.jpg" width="100%" layout="fill" objectFit="cover" />
                                        </div> */}
                                        <Item name="Risotto s chřestem" subtitle="ředkvičky, parmazán" price="259  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Ryby</_></h3>
                                        <Item name="Losos" subtitle="konfitovaný brambor, mačkaný hrášek, okurkový salát, kopr" price="409  " />
                                        <Item name="Ryba dne" price="499  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Maso</_></h3>
                                        <Item name="Hovězí svíčková/rib eye steak" subtitle="batátové hranolky, pepřová omáčka" price="559 / 449  " />
                                        <Item name="Kuřecí prso" subtitle="karotkové pyré, divoká brokolice, silná demiglace, karotkové chipsy" price="339  " />
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Každý pátek a sobotu</_></h4>
                                        <Item name="New One burger" subtitle="hovězí mleté, cheddar, rajče, cibulová marmeláda, chipotle majonéza, batátové hranolky" price="379  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Přílohy</_></h3>
                                        <Item name="Bramborová kaše" price="59  " />
                                        <Item name="Baby brambor" price="59  " />
                                        <Item name="Míchaný listový salát" price="69  " />
                                        <Item name="Grilovaná zelenina" price="79  " />
                                        <Item name="Focaccia, košík" price="59  " />
                                        {/* <Item name="Rozmarýnová bruschetta" price="59  " /> */}

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Saláty</_></h3>
                                        <Item name="Salát Caesar" subtitle="kuřecí maso, pancetta, krutony, parmazán" price="289  " />
                                        <Item name="Salát s gratinovaným kozím sýrem" subtitle="červený grep, mango dresink, medové para ořechy" price="329  " />
                                        <Item name="Salát s hovězí svíčkovou" subtitle="olivy taggiasche, sušená rajčata, divoká brokolice, burrata" price="349  " />
                                        <Item name="Salát s gorgonzolou" subtitle="jahody, pekanové ořechy v medu" price="319  " />
                                        {/* <Item name="Teplý salát z baby brambor" subtitle="cibule, pancetta, uzená majonéza, hovězí svíčková" price="309  " /> */}
                                        {/* <Item name="" subtitle="" price="" /> */}


                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Pochutiny</_></h3>
                                        <Item name="Uzené kešu" subtitle="" price="89  " />
                                        <Item name="Grissini" subtitle="" price="59  " />
                                        <Item name="Marinované olivy" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Dezerty</_></h3>
                                        <Item name="Tiramisu" subtitle="" price="119  " />
                                        <Item name="Vanilkový cheesecake" subtitle="s lesním ovocem" price="119  " />
                                        <Item name="Dezerty dle denní nabídky" subtitle="(Pro více informací se obraťte na obsluhující personál.)" price="" kc={false} />
                                    </div>
                                </div>
                            </Section>
                            <Section
                                title="Snídaně á la carte"
                                name="snidane"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-120px">
                                    <div>
                                        <h3 className="text-18 md:text-24 font-semibold my-20px"></h3>
                                        <Item name="Káva a croissant" subtitle="" price="79  " />
                                        <Item name="Káva, croissant a pomerančový džus" subtitle="" price="99  " />
                                        <Item name="New One granola" subtitle="jogurt, med, lísko-čokoládové máslo, ovoce" price="129  " />
                                        <Item name="Palačinka s povidly" subtitle="vanilkový tvaroh, strouhaná čokoláda, ovoce" price="139  " />
                                        <Item name="Ovesná kaše s pečenými meruňkami" subtitle="lísko-čokoládové máslo, med" price="139  " />
                                        <Item name="3 vejce do skla" subtitle="lanýžový olej, parmazán, pažitka, toast" price="129  " />
                                        <Item name="Vejce míchaná 3ks s pažitkou" subtitle="máslo, pečivo" price="99  " />
                                        <Item name="Vejce míchaná 3ks s cibulkou a pažitkou" subtitle="máslo, pečivo" price="109  " />
                                        <Item name="Vaječná omeleta ze 3 vajec" subtitle="pražská šunka, scamorza, baby špenát, pečivo" price="149  " />
                                        <Item name="New One párky 3ks" subtitle="hořčice, křen, podmáslový chléb" price="139  " />
                                        <Item name="Anglická snídaně" subtitle="vejce, bavorská klobása, fazole, slanina, žampiony" price="199  " />
                                        <Item name="Vejce Benedikt" subtitle="zastřené vejce, holandská omáčka, pečená šunka od kosti, pečivo" price="189  " />
                                        <Item name="Vejce Florentine" subtitle="zastřené vejce, holandská omáčka, baby špenát, pečivo" price="189  " />
                                        {/* <Item name="" subtitle="" price="  " />
                                        <Item name="" subtitle="" price="  " />

                                        <Item name="Spirulina bowl" subtitle="banán, granola, směs semínek" price="119  " />
                                        <Item name="Vafle s nutellou" subtitle="banán, slaný karamel, arašídy" price="129  " />
                                        <Item name="Špaldová kaše s lískooříškovým mlékem" subtitle="lískové ořechy, med, maliny, borůvky" price="129  " />
                                        <Item name="3 vejce do skla" subtitle="lanýžový olej, parmazán, pažitka, toast" price="119  " />
                                        <Item name="Vejce míchaná 3 ks s pažitkou" subtitle="máslo, domácí pečivo" price="89  " />
                                        <Item name="Vejce míchaná 3 ks s cibulkou a pažitkou" subtitle="máslo, domácí pečivo" price="99  " />
                                        <Item name="Vaječná omeleta" subtitle="ze 3 vajec s pražskou šunkou, baby špenátem, sýr Gruyére, domácí pečivo" price="149  " />
                                        <Item name="Anglická snídaně" subtitle="vejce, bavorská klobáska, slanina, žampiony, rajče, máslo, domácí pečivo" price="179  " />
                                        <Item name="Smaženka" subtitle="hrubozrnná hořčice, šunka, grilovaná zelenina" price="159  " />
                                        <Item name="Vejce Benedikt" subtitle="zastřené vejce přelité holandskou omáčkou, pečená šunka od kosti, domácí pečivo" price="169  " />
                                        <Item name="Vejce Florentine" subtitle="zastřené vejce přelité holandskou omáčkou, baby špenát, domácí pečivo" price="169  " /> */}

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Nápoje</_></h3>
                                        <Item name="Pomerančový fresh juice" subtitle="" price="79  " />
                                        <Item name="Jablečný fresh juice" subtitle="" price="79  " />
                                        <Item name="Grapefruitový fresh juice" subtitle="" price="79  " />
                                        <Item name="Jahodové smoothie" subtitle="" price="79  " />
                                        <Item name="Banánové smoothie s pomerančem" subtitle="" price="79  " />
                                        <Item name="Smoothie z lesního ovoce" subtitle="" price="79  " />
                                    </div>
                                    <div>
                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Káva</_></h3>
                                        <Item name="Espresso" subtitle="" price="54  " />
                                        <Item name="Espresso speciál" subtitle="" price="59  " />
                                        <Item name="Espresso ristretto" subtitle="" price="54  " />
                                        <Item name="Espresso lungo" subtitle="" price="54  " />
                                        <Item name="Espresso doppio" subtitle="" price="79  " />
                                        <Item name="Espresso tonic" subtitle="" price="89  " />
                                        <Item name="Espresso macchiato" subtitle="" price="59  " />
                                        <Item name="Cappuccino" subtitle="" price="69  " />
                                        <Item name="Cappuccino doppio" subtitle="" price="89  " />
                                        <Item name="Flat white" subtitle="" price="84  " />
                                        <Item name="Caffè latte macchiato" subtitle="" price="74  " />
                                        <Item name="Caffè latte" subtitle="" price="74  " />
                                        <Item name="Caffè latte jumbo" subtitle="" price="99  " />
                                        <Item name="Frappuccino" subtitle="" price="79  " />
                                        <Item name="Caffè gelato (se zmrzlinou a šlehačkou)" subtitle="" price="89  " />
                                        <Item name="Caffè bombardino" subtitle="" price="89  " />
                                        <Item name="Caffè irlandese (s whiskey a smetanou)" subtitle="" price="89  " />
                                        <Item name="Příchuť kávy dle nabídky" subtitle="" price="10  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Čaje</_></h3>
                                        <Item name="Z čerstvé máty s medem" subtitle="" price="59  " />
                                        <Item name="Z čerstvého zázvoru s medem" subtitle="" price="59  " />
                                        <Item name="Sypaný čaj Tea Forte s medem" subtitle="" price="69  " />
                                    </div>
                                </div>
                            </Section>
                            <Section
                                title="Nápojový list"
                                name="napojovy-list"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-120px">
                                    <div>
                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Nápoje</_></h3>
                                        {/* <Item name="" subtitle="" price="  " /> */}
                                        <Item name="Bezinková limonáda" subtitle="" price="69  " />
                                        <Item name="Citronová limonáda" subtitle="" price="69  " />
                                        <Item name="Zázvorová limonáda" subtitle="" price="69  " />
                                        <Item name="Okurková limonáda" subtitle="" price="79  " />
                                        <Item name="Americká limonáda" subtitle="" price="79  " />
                                        <Item name="Pomerančový fresh juice" subtitle="" price="79  " />
                                        <Item name="Jablečný fresh juice" subtitle="" price="79  " />
                                        <Item name="Grapefruitový fresh juice" subtitle="" price="79  " />
                                        <Item name="Jahodové smoothie" subtitle="" price="79  " />
                                        <Item name="Banánové smoothie s pomerančem" subtitle="" price="79  " />
                                        <Item name="Smoothie z lesního ovoce" subtitle="" price="79  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Vody</_></h3>
                                        <Item name="Mattoni perlivá" subtitle="" price="45  " />
                                        <Item name="Mattoni jemně perlivá" subtitle="" price="45  " />
                                        <Item name="Mattoni neperlivá" subtitle="" price="45  " />
                                        <Item name="Roemerquelle perlivá" subtitle="" price="49 / 89  " />
                                        <Item name="Roemerquelle neperlivá" subtitle="" price="49 / 89  " />
                                        <Item name="Roemerquelle lemongrass" subtitle="" price="54  " />
                                        <Item name="Džbán vody s čerstvou mátou a citrónem" subtitle="" price="29 / 49  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Džusy</_></h3>
                                        <Item name="Cappy" subtitle="pomeranč, jablko, multivitamin, ananas, jahoda, černý rybíz, meruňka, hruška, grep" price="49  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Nealkoholické nápoje</_></h3>
                                        <Item name="Coca-Cola" subtitle="" price="49  " />
                                        <Item name="Coca-Cola light" subtitle="" price="49  " />
                                        <Item name="Coca-Cola zero" subtitle="" price="49  " />
                                        <Item name="Coca-cola ochucená" subtitle="vanilla, cherry, passion fruit" price="49  " />
                                        <Item name="Sprite, Fanta" subtitle="" price="49  " />
                                        <Item name="Kinley Tonic Water, Kinley Ginger Ale, Kinley Bitter Rose" subtitle="" price="49  " />
                                        <Item name="Crodino aperitivo nealko" subtitle="" price="59  " />
                                        <Item name="Red Bull" subtitle="" price="69  " />
                                        <Item name="Fever-Tree Premium Indian Tonic Water" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Pivo</_></h3>
                                        <Item name="Pilsner Urquell čepovaný" subtitle="" price="45  " />
                                        <Item name="Radegast Birell lahvový" subtitle="" price="39  " />
                                        <Item name="Stella Artois lahvová" subtitle="" price="49  " />
                                        <Item name="Apple Cider lahvový" subtitle="" price="49  " />
                                        <Item name="Corona lahvová" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Káva</_></h3>
                                        <Item name="Espresso" subtitle="" price="54  " />
                                        <Item name="Espresso speciál" subtitle="" price="59  " />
                                        <Item name="Espresso ristretto" subtitle="" price="54  " />
                                        <Item name="Espresso lungo" subtitle="" price="54  " />
                                        <Item name="Espresso doppio" subtitle="" price="79  " />
                                        <Item name="Espresso tonic" subtitle="" price="89  " />
                                        <Item name="Espresso macchiato" subtitle="" price="59  " />
                                        <Item name="Cappuccino" subtitle="" price="69  " />
                                        <Item name="Cappuccino doppio" subtitle="" price="89  " />
                                        <Item name="Flat white" subtitle="" price="84  " />
                                        <Item name="Caffè latte macchiato" subtitle="" price="74  " />
                                        <Item name="Caffè latte" subtitle="" price="74  " />
                                        <Item name="Caffè latte jumbo" subtitle="" price="99  " />
                                        <Item name="Frappuccino" subtitle="" price="79  " />
                                        <Item name="Caffè gelato (se zmrzlinou a šlehačkou)" subtitle="" price="89  " />
                                        <Item name="Caffè bombardino" subtitle="" price="89  " />
                                        <Item name="Caffè irlandese (s whiskey a smetanou)" subtitle="" price="89  " />
                                        <Item name="Příchuť kávy dle nabídky" subtitle="" price="10  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Čaje</_></h3>
                                        <Item name="Z čerstvé máty s medem" subtitle="" price="59  " />
                                        <Item name="Z čerstvého zázvoru s medem" subtitle="" price="59  " />
                                        <Item name="Sypaný čaj Tea Forte" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Teplé nápoje</_></h3>
                                        <Item name="Horká čokoláda" subtitle="" price="69  " />
                                        <Item name="Svařené víno" subtitle="" price="69  " />
                                        <Item name="Bombardino se šlehačkou" subtitle="" price="79  " />
                                    </div>
                                    <div>
                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Vermuty</_></h3>
                                        <Item name="Lillet" subtitle="" price="69  " />
                                        <Item name="Martini Extra Dry" subtitle="" price="69  " />
                                        <Item name="Martini Bianco" subtitle="" price="69  " />
                                        <Item name="Martini Rosato" subtitle="" price="69  " />
                                        <Item name="Martini Rosso" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Likéry/bittery</_></h3>
                                        <Item name="Campari Bitter" subtitle="" price="69  " />
                                        <Item name="Jägermeister" subtitle="" price="69  " />
                                        <Item name="Amaretto Disaronno Originale" subtitle="" price="69  " />
                                        <Item name="Porto Ruby Sandeman" subtitle="" price="69  " />
                                        {/* <Item name="Grappa bianco Ramazzotti" subtitle="" price="69  " /> */}
                                        <Item name="Jack Daniel’s Honey" subtitle="" price="79  " />
                                        <Item name="Becherovka" subtitle="" price="59  " />
                                        <Item name="Fernet Stock" subtitle="" price="59  " />
                                        <Item name="Baileys Irish Cream" subtitle="" price="59  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Giny</_></h3>
                                        <Item name="Beefeater London Dry Gin" subtitle="" price="69  " />
                                        <Item name="Bombay Sapphire Dry Gin" subtitle="" price="69  " />
                                        <Item name="Plymouth Gin" subtitle="" price="79  " />
                                        <Item name="Tanqueray London Dry Gin" subtitle="" price="79  " />
                                        <Item name="Hendrick’s Gin" subtitle="" price="89  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Whisk(e)y</_></h3>
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Irské whiskey</_></h4>
                                        <Item name="Jameson" subtitle="" price="69  " />
                                        <Item name="Tullamore Dew" subtitle="" price="69  " />
                                        <Item name="Jameson Black Barrel" subtitle="" price="99  " />
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Skotské whiskey</_></h4>
                                        <Item name="Chivas Regal 12" subtitle="" price="90  " />
                                        <Item name="Glenmorangie The Original" subtitle="" price="130  " />
                                        <Item name="The Glenlivet 15 Year Old French Oak Reserve" subtitle="" price="150  " />
                                        <Item name="Ardberg Ten" subtitle="" price="150  " />
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Americké whiskey</_></h4>
                                        <Item name="Jack Daniel’s No. 7" subtitle="" price="70  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Bourbony</_></h3>
                                        {/* <Item name="Four Roses" subtitle="" price="59  " /> */}
                                        <Item name="Wild Turkey" subtitle="" price="69  " />
                                        <Item name="Four Roses" subtitle="" price="69  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Koňaky</_></h3>
                                        <Item name="Martell V.S." subtitle="" price="95  " />
                                        <Item name="Martell V.S.O.P" subtitle="" price="150  " />
                                        <Item name="Martell X.O" subtitle="" price="450  " />
                                        <Item name="Rémy Martin V.S.O.P." subtitle="" price="150  " />
                                        <Item name="Courvoisier X.O. Impérial" subtitle="" price="300  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Vodky</_></h3>
                                        <Item name="Absolut" subtitle="" price="69  " />
                                        <Item name="Absolut Citron/ Mandarin/Raspberri/Vanilia" subtitle="" price="69  " />
                                        <Item name="Russian Standard Original" subtitle="" price="69  " />
                                        <Item name="Grey Goose" subtitle="" price="109  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Rumy</_></h3>
                                        <Item name="Havana Club Añejo 3 Años" subtitle="" price="69  " />
                                        <Item name="Havana Club Especial" subtitle="" price="79  " />
                                        <Item name="Havana Club Añejo 7 Años" subtitle="" price="90  " />
                                        {/* <Item name="Pacto Navio" subtitle="" price="120  " /> */}
                                        <Item name="Legendario ron" subtitle="" price="120  " />
                                        <Item name="Diplomático Reserva Exclusiva" subtitle="" price="120  " />
                                        <Item name="Ron Zacapa Centenario 23" subtitle="" price="150  " />
                                        <Item name="Ron Zacapa Centenario X.O." subtitle="" price="350  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Ostatní spirity</_></h3>
                                        <Item name="Tequila Olmeca blanco / reposado" subtitle="" price="69  " />
                                        <Item name="Patrón Tequila blanco / reposado" subtitle="" price="100  " />
                                        <Item name="Metaxa 5*" subtitle="" price="60  " />
                                        <Item name="Metaxa 7*" subtitle="" price="90  " />
                                        <Item name="Metaxa 12*" subtitle="" price="130  " />
                                        <Item name="Slivovice Žufánek" subtitle="" price="79  " />
                                        <Item name="Hruškovice Žufánek" subtitle="" price="79  " />
                                        <Item name="Kleiner Silver Plum" subtitle="" price="210  " />
                                        <Item name="Absinthe St. Antoine" subtitle="" price="69  " />
                                    </div>
                                </div>
                            </Section>
                            <Section
                                title="Vinný list"
                                name="vinny-list"

                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-120px">
                                    <div>
                                        {/* <Item name="" subtitle="" price="  " /> */}
                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Moravská zemská a přívlastková vína</_></h3>
                                        <p className="text-brown-text"><_>Vinařství Obelisk (Valtice)</_></p>
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Bílá vína</_></h4>
                                        <Item name="RULANDSKÉ ŠEDÉ 2019 pozdní sběr, vinařství Obelisk, polosuché, 0,75l" subtitle="Víno charakterizuje tmavě zlatá barva a velmi vysoká viskozita. Ve vůni se spojuje citrusové ovoce, pomerančová kůra a lesní med. Chuť je robustní, plná a nalezneme v ní sušené tropické ovoce spolu s jemnou smetanovou linkou. Dochuť je dlouhá, hřejivá s příjemnou kyselinou. Pinot gris moderního střihu s lehkým zbytkovým cukrem, který z něj dělá příjemně nasládlé víno pro libovolnou chvíli." price="350  " />
                                        <Item name="RÝNSKÝ RYZLINK 2018 pozdní sběr, vinařství Obelisk, suché, 0,75l" subtitle="Víno zlatožluté barvy a vysoké viskozity. Intenzivní vůně překvapí spojením citrusových plodů typických pro viniční trať Hintertály s květem lípy a náznakem nazrálosti. Chuť je pikantní s dobře vyváženým poměrem cukru a kyselin. V chuti se odráží terroir místa původu – citrusově, ovocitá chuť s jemnou slaností. Doporučujeme párovat s našimi kuřecími lasagni!" price="350  " />
                                        <Item name="PÁLAVA 2018 výběr z hroznů, vinařství Obelisk, suché, 0,75l" subtitle="Víno zlatožluté barvy se zlatavým viskózním okrajem. Vůně ohromí intenzivní tramínovo-muškátovou linkou doprovázenou kompotovanými mandarinkami a exotickým ovocem. Intenzivní chuť zaujme skvělým poměrem cukr-kyselina a nádhernou stavbou. Dlouhá dochuť pak nabídne směs kompotovaného ovoce a dlouhotrvající kyseliny vybízející k dalšímu napití. Ideální společník našeho kuřete supreme!" price="490  " />
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Růžová vína</_></h4>
                                        <Item name="CUVEÉ ROSÉ 2018 moravské zemské, vinařství Obelisk, polosladké, 0,75l" subtitle="Tmavší růžová barva se starorůžovými odstíny, nižší viskozita. Aroma je středně intenzivní, připomínající řecký meloun a malinové pyré s minerálním podtónem. V chuti převládá v primárním dojmu zbytkový cukr a pak bobulovité ovoce, granátové jablko a sladká červená mletá paprika. Středně dlouhý závěr" price="350  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Rozlévané PROSECCO</_></h3>
                                        <Item name="Sudové Prosecco DOC (Treviso)" subtitle="" price="0,1l / 40  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Rozlévané víno</_></h3>
                                        <Item name="Dle nabídky 0,1l / 0,75l" subtitle="" price="40   / 290  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Zahraniční vína</_></h3>
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Bílá vína</_></h4>
                                        <Item name="CHARDONNAY 2019, (ITA), Toscana IGT, Antinori, vinařství Santa Cristina" subtitle="Toto Chardonnay má slámově žlutou barvu s nazelenalými odlesky. Rafinovaná a delikátní vůně s aroma zelených jablek, zralého ananasu a banánů. Čerstvá, šťavnatá a harmonická chuť s ovocnými tóny, navazujícími na vůni." price="350  " />
                                        <Item name="“CAMPOGRANDE“ ORVIETO CLASSICO (ITA), DOC, Umbria, Antinori, vinařství Santa Cristina" subtitle="Campogrande je směsí odrůd Grechetto a Procanico. Má slámovou barvu, intenzivní vůni s ovocným aroma broskví a meruněk, ale také pomerančových květů. Jemná, čerstvá a svěží chuť s minerálními a ovocnými tóny v závěru." price="350  " />
                                    </div>
                                    <div>
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Růžová vína</_></h4>
                                        <Item name="“CIPRESSETO“ ROSATO 2019 (ITA), Toscana IGT, Antinori, vinařství Santa Cristina" subtitle="Víno z odrůd Sangiovese, Cabernet Sauvignon a Syrah má světle růžovou barvu. Ovocná vůně, kde tóny pomerančových květů a divokých jahod vytvářejí svěží aroma. Jemná a harmonická chuť s plným tělem a delším a ovocným závěrem." price="450  " />
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"><_>Červená vína</_></h4>
                                        <Item name="BARBERA GIRIBALDI, PIEMONTE 2018 (ITA), DOC, Barbera Piemonte “Alma“" subtitle="Víno má jasnou, rubínově červenou barvu. Vůně je ovocná a bohatá s aroma zralých jahod a černých třešní. Chuť je jemná s tóny čerstvého červeného ovoce s lehkou sladkostí v závěru, připomínající opět právě sesbírané čerstvé ovoce. Skvělé k našim domácím těstovinám." price="350  " />
                                        <Item name="ACHELO TOSCANA 2018 (ITA), DOC, Toscana, Crotona, vinařství La Braccesca" subtitle="Samotné víno má purpurovou barvu. Vůně nabízí aroma červeného a černého bobulového ovoce, koření, jako je vanilka a černý pepř. Chuť je plná a bohatá s jemnou ovocnou sladkostí a dlouhým závěrem, ve kterém se objevuje aroma tmavého ovoce, černého rybízu a ostružinového džemu." price="650  " />
                                        <Item name="HARAS DE PIRGUE, RESERVADE PROPRIEDAD BIO 2017 (CHILE), vinařství Haras de Pirgue, Maipo Valey" subtitle="Jedná se o cuvée odrůd Cabernet Sauvignon, Carmenère and Cabernet Franc. Víno má jasnou červenou barvu. Zajímavá vůně má aroma černého ovoce s dotekem tabáku a čerstvých bylin – typické pro vína z Maipo Valley. Chuť je svěží a jemnás příjemným závěrem, dotekem máty a opět čerstvých bylin." price="490  " />

                                        <h3 className="text-18 md:text-24 font-semibold my-20px"><_>Sekty, perlivá vína, Champagne</_></h3>
                                        <Item name="MONTECAMPO PROSECCO SPUMANTE (ITA) DOC, Veneto, Familia Zonin" subtitle="Prosecco Spumante Montecampo má jasnou, světle slámovou barvu. Perlení je jemné a vytrvalé, po nalití vytváří bohatou pěnu. Vůně je intenzivní a krásně ovocná s aroma květů vistárie a čerstvých červených jablek. Chuť je podmanivá a velmi harmonická, s jemnými a delikátními tóny mandlí, typickými pro odrůdu Glera." price="350  " />
                                        <Item name="DI STEFANI PROSECCO „0,15“MILLESIMATO (ITA) DOC, Veneto, Di Stefani, Extra-Dry" subtitle="Výsledné Prosecco má světlou, slámově žlutou barvu s jemným a vytrvalým perlením. Vůně je ovocná s intenzivním aroma jablek, banánů a květin. Chuť je hladká se středně plným tělem a osobitým charakterem. Ideální jako aperitiv." price="550  " />
                                        <Item name="SEKT CUVEÉ RIESLING 2019 vinařství Obelisk, Brut, 0,75l" subtitle="Sekt žlutě zelenkavé barvy doplněné jemným perlením. Ve vůni nalezneme pomelo a mandarinku doplněné lehkou květinovou linkou. Chuť kombinuje zelené jablko a přezrálé citrusy. Dlouhotrvající perlení dává sektu skvělý závěr." price="650  " />
                                        <Item name="SEKT ROSÉ 2018 vinařství Obelisk, Brut, 0,75l" subtitle="Sekt světle růžové barvy s velmi jemným, dlouhotrvajícím perlením. Vůně kombinuje svěží citrusovo-ovocnou linku se smetanovým podtónem. Chuti dominuje svěží lesní ovoce doplněné příjemným perlením a středně dlouhým citrusovým závěrem." price="650  " />
                                        <Item name="VUEVE CLIQUOT PONSARDIN (FRA), Champagne, brut" subtitle="Víno má zlatožlutou barvu s jemným, rafinovaným a lehce smetanový perlením. Vůně je ovocná s minerálním podtónem a pečeným toastem. Ve svěží a čerstvé ovocné chuti nalezneme koření, bílý pepř, mletý zázvor a medově-minerální tóny." price="1990  " />
                                    </div>
                                </div>
                            </Section>
                            <Section
                                title="Cocktail menu"
                                name="cocktail-menu"

                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-120px">
                                    <div>
                                        <h4 className="text-16 md:text-16 font-semibold my-20px"></h4>
                                        <Item name="Olymp" subtitle="Skotská míchaná whisky v kombinaci s ostružinovým likérem, sladkým vermutem a broskvovými bitters Vás zavede až na samý vrchol bájného Olympu" price="159  " />
                                        <Item name="Master of the Seas" subtitle="Bůh rozbouřeného moře a zemětřesení, ukazuje svoji klidnou tvář pomocí českého bylinného likéru a modrého curacaa, bezinkového sirupu, citronového šťávy a zeleného čaje. Aneb vstupte do oázy klidu." price="119  " />
                                        <Item name="Bloody Darkness" subtitle="Krutý pán podsvětí si pro Vás v tomto koktejlů připravuje svoji spravedlivost v podobně stařeného kubánského rumu, třešněvého likéru, citronové šťávy, sirupu z grenadiny a toniku." price="149  " />
                                        <Item name="Underworld Queen" subtitle="Vládkyně ponurého prostředí rozkvétá v podobě grepového ginu, broskvového likéru, citronové šťávy, cukrového sirupu a svěžího prosecca." price="149  " />
                                        <Item name="Nectar of Dionysos" subtitle="Oblíbený vinný bůh si našel zalíbení v osvěžující kombinaci francouzského koňaku, višňového likéru, citronové šťávy, jahodového pyré a prosecca." price="159  " />
                                        <Item name="Athena's Wisdom" subtitle="Moudrá a bojovná bohyně prokazuje svoji chytrost seskupením citronového ginu, marakujového a pomerančového likéru, citronové šťávy, máty s cukrovým sirupem a proseccem." price="159  " />
                                        <Item name="Touch of Afrodité" subtitle="Souhra lásky a vášně v podobě citronové vodky, jablečného likéru, jablečného džusu, citronové šťávy, cukrového sirupu a bílku. Dar od bohyně krásy." price="129  " />
                                        <Item name="Helios's Chariot" subtitle="Sestava zlaté tequily, pomorančového likéru, citronové šťávy, mango-marakujového sirupu s pomorančovými bitters pomáhá bohu slunce převést svůj vůz přes oblohu a dovést nám hřejivé paprsky." price="139  " />
                                        <Item name="Mighty Zeus (non-alko)" subtitle="Mocný král bohů, v jeho nealkoholické elektrizující verzi kombinuje mangové pyré s citronovou šťávou, mandrinkovým džusem a energickým nápojem." price="89  " />
                                        <Item name="Hera's Love (non-alko)" subtitle="Královna bohů prokazuje svoji něhu a lásku v tomto nealkoholickém koktejlu, ve kterém se snoubí kokosový a melounový sirup společně s citronovou šťávou a brusinkovým džusem." price="89  " />
                                        {/* <Item name="Smoked Almond Sour" subtitle="Kombinace mexického mezcalu a mandlového likéru vytváří chuť uzených mandlí, vyvážená chuť pomerančového likéru, limetkové šťávy, cukrového sirupu a bílek na dodání jemnosti tohoto sour drinku." price="129  " />
                                        <Item name="Mr. Cloudy" subtitle="Dvanáctiletá skotská míchaná whisky v kombinaci se švestkovým likérem, sladkým vermutem a ořechovými bitter Vám zaručeně zvedne náladu, i když je venku zamračeno." price="149  " />
                                        <Item name="Wild Bohemian" subtitle="Nespoutaný nefiltrovaný český likér, spolu s rakytníkovým sirupem, citronovou šťávou a tonikem." price="109  " />
                                        <Item name="All Night Long" subtitle="Drink, díky kterému bude zábava trvat až do rána. Kombinace irské whiskey s švestkovo-kávovým likérem, espressem a cukrovým sirupem Vás zaručeně povzbudí." price="149  " />
                                        <Item name="Fallen Rose Hip" subtitle="Londýnský gin spolu s šípkovým sirupem a citronovou šťávou doplněný proseccem." price="129  " />
                                        <Item name="Good and Naughty Spritz" subtitle="Tekutá nadílka v podobě Mandarinkové vodky, hřebíčkového sirupu, citronové šťávy a prosecca." price="109  " />
                                        <Item name="Christmas Time" subtitle="Nalaďte se do vánoční pohody s tímto drinkem, který obsahuje bourbon, lískooříškový likér, perníkový sirup, smetanu a pomerančové bitters." price="129  " />
                                        <Item name="Witches Brew" subtitle="Čarodějný drink, který kombinuje francouzský koňak, zázvorové pivo a kapku perníkového sirupu. Nechte se zlákat tímto opojným lektvarem." price="119  " />
                                        <Item name="Trick or Treat" subtitle="Koleda, koleda – aneb ochutnejte drink se 7-letým kubánským rumem, českým likérem, citronovou šťávou a dýňovým sirupem." price="129  " />
                                        <Item name="Winegroni" subtitle="Chuť lahodného svařeného vína ve studené podobě, obsahuje vinný destilát z Chile, sladký vermut, Campari a skořicový sirup." price="109  " /> */}
                                    </div>
                                    <div>


                                        <h4 className="text-16 md:text-16 font-semibold my-20px">Letní speciály</h4>

                                        <Item name="Aloe Vera Ice Tea" subtitle="" price="59  " />
                                        <Item name="Zelený ice tea s mátou" subtitle="" price="59  " />
                                        <Item name="Broskvový/citronový ice tea" subtitle="" price="59  " />
                                        <Item name="Limonáda mango&maracuja" subtitle="" price="69  " />
                                        <Item name="Malinová limonáda" subtitle="" price="69  " />
                                        <Item name="Jahodové frappuccino" subtitle="" price="79  " />
                                        <Item name="Iced caramel cappuccino" subtitle="" price="79  " />
                                        <Item name="Kokosové iced latte" subtitle="" price="89  " />
                                        {/* <Item name="Domácí pečený čaj" subtitle="dle aktuální nabídky" price="59  " />
                                        <Item name="Šípková limonáda" subtitle="" price="59  " />
                                        <Item name="Čajová infuze" subtitle="Bylinný čaj infuzovaný rakytníkem nebo šípkem" price="59  " />
                                        <Item name="Café New One punč" subtitle="" price="59  " />
                                        <Item name="Perníkové / Dýňové latté" subtitle="" price="79  " />
                                        <Item name="Horká čokoláda s marshmallows" subtitle="" price="79  " />
                                        <Item name="Cointreau čokoláda" subtitle="" price="89  " /> */}
                                    </div>
                                </div>
                            </Section>
                        </ContentContainer>
                    </div>
                </motion.div>
                <ContentContainer onlyReserve>
                    <JanLibra />
                </ContentContainer>
                <div className="lg:hidden">
                    <ContentContainer p onlyReserve>
                        <p className="block lg:hidden text-16 text-brown-inverted mb-16px">
                            <_>Začínal v hotelové kuchyni, kde projevil svůj talent a během dvou let se vypracoval z pozice Chef de Partie na Sous-chef. Tehdy stál u zrodu Café New One, později na dva roky Caféčko opustil, aby nasbíral zkušenosti a inspiraci v zahraničí, zejména na Islandu, v Irsku a na Maltě a vrátil se rovnou na pozici šéfkuchaře.</_>
                        </p>
                        <div className="block lg:hidden border-l border-brown-light pl-24px text-16 italic text-brown-inverted">
                            „<_>V Caféčku pro vás chceme vždy víc</_>“
                        </div>
                    </ContentContainer>
                </div>
                <ContentContainer p onlyReserve>
                    <InstagramGrid />
                </ContentContainer>
            </MenuAndReserve>
        </Layout>

    )
}

interface SectionProps {
    title: string,
    name: string,
    subtitle?: string,
    children: any,
    time?: string
}

export function Section({ title, name, subtitle, children, time }: SectionProps) {
    const { locale } = usePageProps<PageProps>()
    console.log(locale)
    return (
        <div name={name} className="w-full">
            <div className="block md:flex justify-between items-center w-full pt-24px md:pt-80px">
                <h2 className="font-aldo w-full text-36 md:text-64 text-brown-light font-outline-brown leading-tight lowercase">
                    {(t(title) == "Obědové menu") ?
                        locale == "en" ?
                            (t(title))
                            :
                            <Img src="/assets/img/obedovemenu.svg" className="mb-16px w-160px md:w-325px " />
                        :
                        (t(title) == "Snídaně á la carte") ?
                            <Img src="/assets/img/snidane_outline.svg" className="mb-16px w-200px md:w-400px " />
                            :
                            (t(title))
                    }
                </h2>
                {time &&
                    <div className="flex md:flex items-center whitespace-nowrap">
                        <motion.div
                            transition={{ repeat: Infinity, duration: 1.6 }}
                            animate={{ opacity: 0 }}
                            initial={{ opacity: 1 }}
                            className="w-4px h-4px bg-white mr-8px rounded-full"></motion.div>
                        <div className="text-white font-semibold text-14">{t(time)}</div>
                    </div>
                }
            </div>
            {subtitle &&
                <div className="text-16 w-full mb-24px mt-16px md:mt-0">{t(subtitle)}</div>
            }


            <div className="w-full">
                {children}

            </div>
        </div>
    )
}

interface ItemProps {
    name: string,
    subtitle?: string,
    price: string
    kc?:boolean
}

export function Item({ name, subtitle, price, kc = true }: ItemProps) {

    return (
        <div className="w-full flex justify-between items-bottom relative mb-16px">

            <div className=" z-10 text-16  text-brown-inverted max-w-sm  ">
                <span className="bg-brown-dark font-semibold  pr-8px leading-tight">{t(name)}</span>
                {subtitle && <><br /><span className="bg-brown-dark font-normal  pr-8px">{t(subtitle)}</span></>}
            </div>

            {kc&&<div className="bg-brown-dark z-10 pl-8px text-16 font-normal text-brown-inverted self-end whitespace-nowrap">{price} Kč</div>}
            {kc&&<div className="z-0 absolute bottom-6px h-1px w-full bg-brown-inverted opacity-16"></div>}
        </div>
    )
}

function renderMenu(menu) {
    if (menu) {
        var div = document.createElement('div');
        div.innerHTML = menu.trim();
        var table = div.getElementsByTagName('tbody')
        let arrayOfElements = Array.from(table);
        const tds = div.querySelectorAll('td')
        const days = div.querySelectorAll('#home .col-xs-12 .col-xs-12')

        // console.log("days", days)

        let daysArray = Array.prototype.slice.call(days)
        // console.log("daysArray", daysArray)

        let returnedObj = [] as any

        daysArray.map((day) => {

            let string = day.childNodes[1].innerText
            // console.log("string", string)

            string = string.trim();

            let dayItem = { day: string, meals: [] as any }

            // console.log("dayItem", dayItem)
            const tables = Array.prototype.slice.call(day.querySelectorAll('table'))
            // console.log("tables", tables)
            const line = Array.prototype.slice.call(tables[1].querySelectorAll('tr'))
            // console.log("line", line)
            line.map((linepreItem) => {

                const property = Array.prototype.slice.call(linepreItem.querySelectorAll('td'))
                // console.log("property", property)

                let properties = { item: property[1].innerText, price: property[2].innerText }
                // console.log("properties", properties)

                if (!property[1].innerText.includes("Kohoutkové") && !property[1].innerText.includes("kohoutkové")) {
                    dayItem.meals.push(properties)
                }


            })
            // console.log("dayItem", dayItem)
            if (dayItem.meals.length > 0) {
                returnedObj.push(dayItem)
            }
        })
        // console.log("returnedObj", returnedObj)

        return returnedObj

        // let arr = Array.prototype.slice.call(tds)
        // let finalArr = [] as Array<any>

        // let finalObj = [] as any

        // arr.forEach((single, j) => {

        //     let text = single.innerText

        //     text = text.replace("&nbsp;", " ")

        //     finalArr.push(text)
        // })

        // arr = finalArr
        // console.log(arr)

        // for (let i = 0; i < (arr.length / 3) - 1; i++) {

        //     console.log("r")

        //     finalObj.push({
        //         item: arr[(i * 3) + 1],
        //         price: arr[(i * 3) + 2]
        //     })
        // }

        // // 15:32 end of work


        // console.log(finalObj)
        // console.log(div)
        // console.log(table)


        // // Change this to div.childNodes to support multiple top-level nodes
        // return finalObj
    }

}